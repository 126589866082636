import { useEffect, useRef, useState } from 'react';
import './App.css';

function App() {
  const videoRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(`${process.env.PUBLIC_URL}/skelwifhat.png`);

  useEffect(() => {
    const unmuteVideo = () => {
      if (videoRef.current) {
        videoRef.current.muted = false;
      }
    };

    window.addEventListener('click', unmuteVideo);
    window.addEventListener('keypress', unmuteVideo);

    return () => {
      window.removeEventListener('click', unmuteVideo);
      window.removeEventListener('keypress', unmuteVideo);
    };
  }, []);

  useEffect(() => {
    const images = [
      `${process.env.PUBLIC_URL}/skelwifhat.png`,
      `${process.env.PUBLIC_URL}/skelwifhat2.png`,
      `${process.env.PUBLIC_URL}/skelwifhat3.png`
    ];
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % images.length;
      setCurrentImage(images[currentIndex]);
    }, 1200); // Change image every 1.2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <video ref={videoRef} autoPlay loop muted className="App-background">
        <source src={`${process.env.PUBLIC_URL}/video.mp4`} type="video/mp4" />
      </video>
      <div className="bubbles">
        {[...Array(20)].map((_, i) => (
          <div key={i} className="bubble">
            <img src={currentImage} alt="bubble" />
          </div>
        ))}
      </div>
      <header className="App-header">
        <img src={currentImage} className="App-logo" alt="skelwifhat" />
        <p className="grungy-text">
          Welcome to the ultimate fan site for Skel, SkelWifHat, founder of <a href="https://www.metawin.com" target="_blank" rel="noopener noreferrer">Metawin</a>!
        </p>
        <a
          className="App-link"
          href="https://www.metawin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Metawin
        </a>
      </header>
      <div className="content-spacer"></div> {/* Spacer to push footer down */}
      <footer className="App-footer">
        <p>Initiated by community members from Metawin. Just fun, and good vibes 🔥 *not connected with @meta_winners</p>
        <div className="iframe-container">
          <iframe
            src="https://pump.fun/FCvrUSkPPqst1ER4wybdLgff4TMER6fnmAJ4R7Bbpump"
            title="Moonshots"
            className="moonshot-iframe"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </footer>
    </div>
  );
}

export default App;